import { Link } from "react-router-dom";
export default function NotFoundPage() {
  return (
    <div class="bg-black w-full h-screen flex flex-col items-center justify-center">
      <h1 class="text-6xl font-bold text-center text-white">
        Linknya salah bossq 🙌
      </h1>
      <br />
      <Link to="/">
        <button class="bg-[#510B85] hover:bg-[#41076e] text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105">
          Balik
        </button>
      </Link>
    </div>
  );
}
