import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";

const ConfidenceText = () => {
  return (
    <Swiper
      // Swiper configuration for infinite sliding
      modules={[Autoplay]}
      slidesPerView={1}
      spaceBetween= {480}
      breakpoints={{
        // Breakpoint for mobile devices
        480: {
          slidesPerView: 1,
          spaceBetween: 480,
        },
        // Breakpoint for larger screens
        768: {
          slidesPerView: 1,
          spaceBetween: 1000,
        },
        1280: {
          slidesPerView: 1,
          spaceBetween: 1500,
        },
      }}
      loop={true} // Enables infinite loop
      autoplay={{
        delay: 0, // Continuously auto-slide
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      }}
      speed={6000} // Control speed for smoothness (5 seconds per full slide)
      style={{ animationTimingFunction: "linear" }} // Linear animation
    >
      
      <SwiperSlide>
        <div className="text-3xl md:text-6xl xl:text-8xl whitespace-nowrap text-center libre black-with-white-outline"> 
        <span className="libre text-3xl text-white md:text-6xl xl:text-8xl no-outline"> CONFIDENCE</span> BRING A PLEASURE
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="text-3xl md:text-6xl xl:text-8xl whitespace-nowrap text-center libre black-with-white-outline"> 
        <span className="libre text-3xl text-white md:text-6xl xl:text-8xl no-outline"> CONFIDENCE</span> BRING A PLEASURE
        </div>
      </SwiperSlide>
      
      {/* Repeat slides as needed */}
    </Swiper>
  );
};

export default ConfidenceText;
