import React from "react";
import PerfumeLogo from "../../components/assets/KING HORSE PERFUME LOGO.png";
import Essential from "../../components/assets/Perfume/essential.png";
import slideshow from "../assets/Perfume/SLIDE SHOW.png"

const HeroPerfume = () => {
  return (
    <div id="home" className="h-screen w-full overflow-hidden">
      <div className="flex flex-col  gap-10 justify-start items-center md:flex-row w-fuil h-full">
        <div className="w-36 h-[40%] md:h-auto flex justify-center items-end md:hidden">
          <img src={PerfumeLogo} alt="" />
        </div>
        <div className="w-full h-[70%] md:h-auto flex justify-center items-center md:hidden">
          <img src={Essential} className="object-cover w-full h-full" alt="" />
        </div>
        <div className="w-full hidden md:block">
          <img src={slideshow} className="w-full h-[80vh] object-cover" alt="" />
        </div>
      </div>
    </div>
  );
};

export default HeroPerfume;
