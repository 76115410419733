import { motion } from "framer-motion";
import Kinglogo from "../assets/Logo.webp";
import Dropdown from "../assets/dropdown icon.png";
import { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";

const Navbar = () => {
  let Links = [
    { name: "HOME", url: "#home" },
    { name: "PRODUCT", url: "#aboutus" },
    { name: "SHOP", url: "#howtobuy" },
    { name: "CONTACT US", url: "#contact" },
  ];

  // State to control navbar visibility in mobile view
  let [open, setOpen] = useState(false);

  // State to control dropdown visibility for the "Product" link
  let [productDropdownOpen, setProductDropdownOpen] = useState(false);

  // Animation variants for the dropdown
  const dropdownVariants = {
    hidden: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3,
      },
    },
    visible: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <motion.div className="z-20 fixed w-full top-0">
      <div className="md:flex md:ml-10 px-7 justify-between bg-black md:bg-transparent py-4">
        <div className="font-bold flex cursor-pointer items-center">
          <span>
            <img className="h-10 w-10" src={Kinglogo} alt="" />
          </span>
        </div>
        <div
          onClick={() => setOpen(!open)}
          className="text-2xl text-white absolute right-8 top-6 cursor-pointer md:hidden"
        >
          <AiOutlineMenu name={open ? "close" : "menu"} />
        </div>
        <ul
          className={`md:flex md:items-center absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 transition-all duration-500 ease-in ${
            open ? "top-[72px]" : "top-[-450px]"
          }`}
        >
          {Links.map((Linkitem) => (
            <li
              key={Linkitem.name}
              className="coolanimation md:ml-4 lg:ml-8 md:my-0 md:px-3 bg-black hover:bg-white hover:text-black text-white relative"
              onMouseEnter={() => {
                if (Linkitem.name === "PRODUCT") {
                  setProductDropdownOpen(true);
                }
              }}
              onMouseLeave={() => {
                if (Linkitem.name === "PRODUCT") {
                  setProductDropdownOpen(false);
                }
              }}
            >
              <a
                className="md:pl-0 pl-6 flex md:py-1 py-3 font-bold"
                href={Linkitem.name === "PRODUCT" ? undefined : Linkitem.url}
                onClick={(e) => {
                  if (Linkitem.name === "PRODUCT") {
                    e.preventDefault(); // Prevent default behavior if it's PRODUCT
                    setProductDropdownOpen(!productDropdownOpen); // Toggle dropdown for Product
                  }
                }}
              >
                {Linkitem.name}{" "}
                {Linkitem.name === "PRODUCT" && (
                  <img
                    className="w-3 object-contain ml-3"
                    src={Dropdown}
                    alt=""
                  />
                )}
              </a>

              {Linkitem.name === "PRODUCT" && (
                <motion.div
                  initial="hidden"
                  animate={productDropdownOpen ? "visible" : "hidden"}
                  variants={dropdownVariants}
                  className="absolute left-0 top-full bg-black text-white overflow-hidden z-10"
                >
                  <ul>
                    <li className="hover:bg-gray-700 px-4 py-2">
                      <Link to="/">King Horse Perfume</Link>
                    </li>
                    <li className="hover:bg-gray-700 px-4 py-2">
                      <Link to="/magic">King Horse Magic</Link>
                    </li>
                  </ul>
                </motion.div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

export default Navbar;
