import React from "react";

const InstaPerfume = () => {
  return (
    <div>
      <div className="overflow-hidden text-xl text-center libre">
        <h1 className="libre">FOLLOW US</h1>
        <h1 className="libre">ON INSTAGRAM</h1>
      </div>
      <div className="my-5 flex justify-center items-center">
        Image
      </div>
    </div>
  );
};

export default InstaPerfume;
