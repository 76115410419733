import React from "react";
import CardPic from "../assets/Perfume/CARD.png"

const VipCard = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center w-full h-auto md:justify-around">
      <div className="text-center text-xl md:text-4xl  md:text-left">
        <p>Join <span className="italic">King Horse</span></p>
        <p className="">privilege member</p>
        <div className="px-5 py-2 my-4 text-center md:text-xl border-white border-2 rounded-full">
          <p>Join Now</p>
        </div>
      </div>
      <div className="w-[80vw] md:w-auto">
        <img src={CardPic} className="w-full md:w-96" alt="" />
      </div>
    </div>
  );
};

export default VipCard;
