import Navbar from '../../components/navbar/Navbar'
import HeroPerfume from './HeroPerfume'
import AboutPerfume from './AboutPerfume'
import ProductDisplay from './ProductDisplay'
import ConfidenceText from './ConfidenceText'
import ConfidenceText1 from './ConfidenceText1'
import ConfidenceText2 from './ConfidenceText2'
import OlshopPerfume from './OlshopPerfume'
import Footer from '../Footer'
import VipCard from './VipCard'
import InstaPerfume from './InstaPerfume'

const PerfumeMain = () => {
  return (
    <div className='overflow-hidden text-white w-full overflow-x-hidden'>
      <Navbar/>
      <HeroPerfume/>
      <AboutPerfume/>
      <ProductDisplay/>
      <br />
      <br className='hidden xl:block'/>
      <ConfidenceText/>
      <ConfidenceText1/>
      <ConfidenceText2/>
      <br />
      <br className='hidden xl:block'/>

      <OlshopPerfume/>
      <hr className='pb-10 mx-8'/>
      <VipCard/>
      <hr className='my-10 mx-8'/>
      <InstaPerfume/>
      <Footer/>
    </div>
  )
}

export default PerfumeMain